import store from '@/store'

export const getAccessToken = () => {
  const accessToken = store.getters['User/accessToken']
  const tokenExpire = store.getters['User/tokenExpire']
  const dateTime = new Date().getTime()
  if (!accessToken || !tokenExpire || tokenExpire <= dateTime) {
    store.dispatch('User/resetUser')
    return null
  }
  return accessToken
}

export const getRole = () => {
  const role = store.getters['User/role']
  return role
}

export const getAuthToken = () => {
  let token = {}
  const accessToken = getAccessToken()
  if (accessToken) {
    token = {
      key: 'Authorization',
      value: `Bearer ${accessToken}`
    }
  }
  return token
}

export default {
  getAccessToken,
  getAuthToken
}

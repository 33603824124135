<template>
  <v-container
    id="header-branding"
    fluid>
    <v-row align="center">
      <v-col
        cols="12"
        md="6">
        <v-img
          :height="120"
          src="/images/court-branding-2.png"
          contain />
      </v-col>
      <v-col
        cols="12"
        md="6">
        <h1 class="text-subtitle-1 text-center">
          ฐานข้อมูลโครงการพัฒนาวิชาการเชิงระบบเพื่อให้คำปรึกษาด้านจิตสังคมในระบบศาลแก่ผู้ต้องหาคดียาเสพติด
        </h1>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

export default {
  name: 'HeaderBranding'
}

</script>

<style lang="scss" scoped>
#header-branding {
  background-color: #ffc107;
}
</style>

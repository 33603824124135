import Vue from 'vue'
import App from '@/App.vue'
import router from '@/router'
import store from '@/store'

import Vuetify from '@/plugins/Vuetify'
import Notification from '@/services/notification'

import '@/assets/scss/style.scss'

Vue.config.productionTip = false
Vue.prototype.$notify = new Notification()

new Vue({
  router,
  store,
  vuetify: Vuetify,
  render (h) { return h(App) }
}).$mount('#app')

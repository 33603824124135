import Vue from 'vue'
import VueRouter from 'vue-router'
import { getAccessToken } from '@/assets/js/auth'

Vue.use(VueRouter)

const routes = [
  {
    path: '/login',
    name: 'LoginPage',
    component: () => import('@/views/Auth/Login.vue'),
    meta: {
      title: 'Login'
    }
  },
  {
    path: '/register',
    name: 'RegisterPage',
    component: () => import('@/views/Auth/Register.vue'),
    meta: {
      title: 'Register'
    }
  },
  {
    path: '/logout',
    name: 'LogoutPage',
    component: () => import('@/views/Auth/Logout.vue'),
    meta: {
      title: 'Logout'
    }
  },
  {
    path: '/',
    name: 'HomePage',
    component: () => import('@/views/Home/Home.vue'),
    meta: {
      title: 'หน้าหลัก',
      auth: true
    }
  },
  {
    path: '/psychologists-list',
    name: 'PsychologistsListPage',
    component: () => import('@/views/Psychologist/PsychologistsList.vue'),
    meta: {
      title: 'เครือข่ายนักจิตวิทยา/นักสังคมสงเคราะห์',
      auth: true
    }
  },
  {
    path: '/psychologists-detail/:id',
    name: 'PsychologistsDetailPage',
    component: () => import('@/views/Psychologist/PsychologistsDetail.vue'),
    meta: {
      title: 'เครือข่ายนักจิตวิทยา/นักสังคมสงเคราะห์',
      auth: true
    }
  },
  {
    path: '/psycho-social-clinics',
    name: 'PsychoSocialClinicsPage',
    component: () => import('@/views/PsychoSocialClinic/PsychoSocialClinic.vue'),
    meta: {
      title: 'เครือข่ายคลินิคจิตสังคม',
      auth: true
    }
  },
  {
    path: '/lecturer-networks',
    name: 'LecturerNetworksPage',
    component: () => import('@/views/LecturerNetwork/LecturerNetwork.vue'),
    meta: {
      title: 'เครือข่ายวิทยากร',
      auth: true
    }
  },
  {
    path: '/knowledge',
    name: 'KnowledgePage',
    component: () => import('@/views/Knowledge/Knowledge.vue'),
    meta: {
      title: 'สื่อการเรียนรู้',
      auth: true
    }
  },
  {
    path: '/knowledge/video/:id',
    name: 'KnowledgeVideoPage',
    component: () => import('@/views/Knowledge/KnowledgeVideo.vue'),
    meta: {
      title: 'สื่อการเรียนรู้',
      auth: true
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  if (to.meta?.auth) {
    const token = getAccessToken()
    if (!token) {
      return router.replace({ name: 'LogoutPage' })
    }
  }
  return next()
})

export default router

<template>
  <v-app-bar
    id="appbar"
    color="#004D40">
    <v-row class="d-none d-md-flex">
      <v-col
        v-for="(item, index) in menus"
        :key="`menus-${index}`"
        class="d-flex align-center justify-center">
        <router-link
          :to="item.to"
          class="text-center white--text text-body-1 text-pre text-decoration-none w-full"
          v-text="item.text">
        </router-link>
      </v-col>
    </v-row>
    <v-app-bar-title
      v-if="title"
      class="d-block d-md-none white--text">
      {{ title }}
    </v-app-bar-title>
    <v-spacer class="d-block d-md-none" />
    <v-app-bar-nav-icon
      class="d-block d-md-none white--text"
      @click="onClickNavIcon()" />
  </v-app-bar>
</template>

<script>
export default {
  name: 'HeaderNavbar',
  props: {
    menus: {
      type: Array,
      default: () => ([])
    }
  },
  computed: {
    title () {
      return this.$route.meta?.title || ''
    }
  },
  methods: {
    onClickNavIcon () {
      this.$emit('open')
    }
  }
}
</script>

<style lang="scss" scoped>
#appbar {
  position: sticky;
  top: -1px;
  z-index: 6;
  flex: unset;
}
</style>

const encodeKey = '3NC0d3K3y'
const encodeTaget = '1'

export const base64Encode = (_str) => {
  let str = _str
  if (typeof str !== 'string') {
    if (typeof str === 'number') {
      str = str.toString()
    } else {
      throw new Error('Text to encode must be a string or a number.')
    }
  }

  return Buffer.from(str, 'utf8').toString('base64')
}

export const base64Decode = (str) => {
  if (typeof str !== 'string') {
    throw new Error('Input value must be a string.')
  }

  return Buffer.from(str, 'base64').toString('utf8')
}

export const base64EncodeByKey = (str, key = 'e', taget = 'e') => {
  const regx = new RegExp(taget, 'g')
  const regx2 = new RegExp(encodeTaget, 'g')
  return base64Encode(base64Encode(str).replace(regx, key)).replace(regx2, encodeKey)
}

export const base64DecodeByKey = (str, key = 'e', taget = 'e') => {
  const regx = new RegExp(key, 'g')
  const regx2 = new RegExp(encodeKey, 'g')
  return base64Decode(base64Decode(str.replace(regx2, encodeTaget)).replace(regx, taget))
}

export default {
  encode: base64Encode,
  decode: base64Decode,
  encodeByKey: base64EncodeByKey,
  decodeByKey: base64DecodeByKey
}

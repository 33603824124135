import HttpRequest from './HttpRequest'

class AuthProvider extends HttpRequest {
  login (payload) {
    return this.post('/auth/local', payload)
  }

  register (payload) {
    return this.post('/auth/local/register', payload)
  }
}

export default AuthProvider

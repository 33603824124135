import jwtDecode from 'jwt-decode'
import AuthProvider from '@/resources/AuthProvider'

const authService = new AuthProvider()

const state = {
  accessToken: null,
  username: null,
  tokenExpire: null,
  user: {
    id: null,
    firstname: null,
    lastname: null,
    email: null,
    agency: null
  },
  role: null
}

const actions = {
  async login ({ dispatch }, payload) {
    const data = await authService.login({
      identifier: payload.identifier,
      password: payload.password
    })
    const jwtData = jwtDecode(data.jwt) || {}
    const dataPayload = {
      accessToken: data?.jwt || null,
      username: data?.user?.username || null,
      tokenExpire: jwtData?.exp ? jwtData.exp * 1000 : null,
      user: {
        ...data.user,
        agency: data.user?.agency?.name || null
      },
      role: data?.user?.role?.name || null
    }
    if (data?.user?.isActive && dataPayload.accessToken) {
      dispatch('setUser', dataPayload)
    }
    return data
  },
  setUser ({ commit }, payload) {
    commit('SET_USER', payload)
  },
  resetUser ({ commit }) {
    commit('RESET_USER')
  }
}

const mutations = {
  SET_USER (state, payload) {
    state.accessToken = payload?.accessToken || null
    state.username = payload?.username || null
    state.tokenExpire = payload?.tokenExpire || null
    state.role = payload?.role || null
    state.user = {
      id: payload?.user?.id || null,
      firstname: payload?.user?.firstname || null,
      lastname: payload?.user?.lastname || null,
      email: payload?.user?.email || null,
      agency: payload?.user?.agency || null
    }
  },
  RESET_USER (state) {
    state.accessToken = null
    state.username = null
    state.tokenExpire = null
    state.user = {
      id: null,
      firstname: null,
      lastname: null,
      email: null,
      agency: null
    }
  }
}

const getters = {
  userData: (state) => state.user,
  userId: (state) => state.user.id,
  accessToken: (state) => state.accessToken,
  tokenExpire: (state) => state.tokenExpire,
  role: (state) => state.role,
  username: (state) => state.username
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
}

<template>
  <v-app>
    <HeaderBranding />
    <HeaderNavbar
      v-if="!isPublic"
      :menus="menus"
      @open="open()" />
    <Sidebar
      v-model="sidebarActive"
      :menus="menus" />
    <v-main class="my-4">
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
import HeaderBranding from '@/components/HeaderBranding.vue'
import HeaderNavbar from '@/components/HeaderNavbar.vue'
import Sidebar from '@/components/Sidebar.vue'

export default {
  components: {
    HeaderBranding,
    HeaderNavbar,
    Sidebar
  },
  data () {
    return {
      menus: [
        { text: 'หน้าหลัก', to: '/' },
        { text: 'เครือข่ายคลินิคจิตสังคม', to: '/psycho-social-clinics' },
        { text: 'เครือข่ายนักจิตวิทยา\nนักสังคมสงเคราะห์', to: '/psychologists-list' },
        { text: 'เครือข่ายวิทยากร', to: '/lecturer-networks' },
        { text: 'สื่อการเรียนรู้', to: '/knowledge' },
        { text: 'ออกจากระบบ', to: '/logout' }
      ],
      sidebarActive: false
    }
  },
  computed: {
    isPublic () {
      const publicRouteNames = [
        'LoginPage',
        'RegisterPage',
        'LogoutPage'
      ]
      return publicRouteNames.some((routeName) => routeName === this.$route.name)
    }
  },
  methods: {
    open () {
      this.sidebarActive = true
    }
  }
}
</script>

<style lang="scss">
</style>

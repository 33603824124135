<template>
  <v-fade-transition>
    <v-card
      v-if="isActive"
      width="450"
      class="notify-alert__item notify-alert__item--center alert-card"
      flat>
      <div class="d-flex justify-center">
        <v-icon
          color="white"
          class="my-8"
          size="80">
          {{ icon }}
        </v-icon>
      </div>

      <v-card-title
        v-if="title"
        class="my-text-title "
        v-text="title" />
      <v-card-text class="text-center">
        <div class="title my-text-content  mb-1">
          {{ message }}
        </div>
        <!-- <div
          v-if="description"
          class="grey--text body-2 mb-6">
          {{ description }}
        </div> -->
      </v-card-text>
      <v-card-actions>
        <v-row justify="end">
          <v-col cols="auto">
            <v-btn
              color="white"
              outlined
              @click="onConfirm()">
              {{ confirmText }}
            </v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-fade-transition>
</template>

<script>
import Vue from 'vue'
import { VCard, VCardTitle, VRow, VCol, VFadeTransition, VIcon, VBtn } from 'vuetify/lib'

export default Vue.extend({
  name: 'ServiceAlert',
  components: { VCard, VCardTitle, VRow, VCol, VFadeTransition, VIcon, VBtn },
  props: {
    title: {
      type: String,
      default: undefined
    },
    type: {
      type: String,
      default: undefined
    },
    icon: {
      type: String,
      default: 'mdi-bell'
    },
    color: {
      type: String,
      default: undefined
    },
    message: {
      type: String,
      required: true
    },
    description: {
      type: String,
      default: ''
    },
    timeout: {
      type: Number,
      default: 100000
    },
    confirmText: {
      type: String,
      default: 'OK'
    },
    confirmCallback: {
      type: Function,
      default: () => {}
    }
  },
  data () {
    return {
      isActive: true
    }
  },
  beforeMount () {
    this.setupContainer()
  },
  mounted () {
    this.show()
  },
  methods: {
    show () {
      if (this.shouldQueue()) {
        this.queueTimer = setTimeout(this.show, 250)
        return
      }
      this.parentTop.insertAdjacentElement('afterbegin', this.$el)
      const overflow = document.createElement('div')
      overflow.className = 'notify-alert__overflow'
      this.parentTop.appendChild(overflow)
      this.isActive = true
      // setTimeout(this.dismiss, this.timeout)
    },
    shouldQueue () {
      return this.parentTop.childElementCount > 0
    },
    setupContainer () {
      this.parentTop = document.querySelector('.notify-alert')
      if (this.parentTop) return
      if (!this.parentTop) {
        this.parentTop = document.createElement('div')
        this.parentTop.className = 'notify-alert'
      }
      const container = document.querySelector('.v-application--wrap')
      container.appendChild(this.parentTop)
    },
    onConfirm () {
      this.confirmCallback()
      this.dismiss()
    },
    dismiss () {
      this.isActive = false
      this.$destroy()
      this.parentTop.remove()
    }
  }
})
</script>

<style lang="scss">
.notify-alert {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: 9999;
  pointer-events: none;
  &__item {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    align-items: center;
    padding: 4px 16px;
    animation-duration:150ms;
    margin:.5em 0;
    box-shadow: 0px 22.59px 63.2px rgba(19, 10, 46, 0.08),
      0px 6.02px 24.09px rgba(19, 10, 46, 0.07),
      0px 2.26px 10.54px rgba(19, 10, 46, 0.25),
      0px 0.75px 2.26px rgba(19, 10, 46, 0.13) !important;
    border-radius:.25em;
    pointer-events:auto;
    color:#fff;
    min-height:3em;
    z-index: 1;
    &--center {
      align-self:center;
      margin: 0 auto;
    }
  }
  &__overflow {
    width: 100%;
    height: 100%;
    position: absolute;
    background-color: #cbcbcb80;
  }
}

.alert-card{
  background-color: rgb(253, 117, 117) !important;
}

.my-text-title{
  color: white !important;
  display: flex !important;
  justify-content: center !important;

}

.my-text-content{
  color: white !important;
  font-weight: 300 !important;
}
</style>

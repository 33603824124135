<template>
  <v-navigation-drawer
    v-model="drawer"
    fixed
    temporary
    right
    app>
    <v-list dense>
      <v-list-item
        v-for="menu in menus"
        :key="menu.text"
        :to="menu.to"
        link>
        <v-list-item-content>
          <v-list-item-title>{{ menu.text }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
export default {
  name: 'SideBar',
  props: {
    value: {
      type: Boolean,
      require: true
    },
    menus: {
      type: Array,
      default: () => ([])
    }
  },
  computed: {
    drawer: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('input', val)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
